<template>
  <div>
    <member-breadcrumb :title="title">
      <template slot="right-part">
        <el-button @click="$router.back()" size="small">返回</el-button>
      </template>
    </member-breadcrumb>

    <el-form ref="payOrderForm" :rules="rules" :model="model" method="post" id="payOrderForm"
             onsubmit="return false" label-width="100px">


      <el-form-item label="项目编号：">
        <span>{{model.projectId}}</span>
      </el-form-item>

      <el-form-item label="项目名称：">
        <span>{{info.projectName}}</span>
      </el-form-item>
      <el-form-item label="项目报价：">
        <span>{{info.price/100}}元</span>
      </el-form-item>
      <el-form-item label="已付金额：">
        <span>{{info.moneyPaid/100}}元</span>
      </el-form-item>


      <el-form-item prop="payMoney" label="本次付款：">
        <el-input v-model="model.payMoney" style="width: 215px"></el-input>
        <span class="info ml10">元</span>
      </el-form-item>


      <el-form-item prop="payType" label="付款方式：">
        <el-radio v-model="model.payType" label="1">在线支付</el-radio>
        <el-radio v-model="model.payType" label="2">对公转账</el-radio>
      </el-form-item>

      <el-form-item label="收款账户：" v-show="model.payType==='2'">
        <div>
          账户名称：重庆沃古软件有限公司<br>
          账户号码：50050107420000000521<br>
          开户银行：中国建设银行股份有限公司重庆南岸金子村支行
        </div>
      </el-form-item>


      <el-form-item>
        <el-button type="primary" :loading="loading" @click="submitForm('payOrderForm')">提交</el-button>
      </el-form-item>
    </el-form>
    <div>
      <p class="warn"><b>提示：</b></p>
      <p class="info">请先和销售经理沟通确认本期付款金额后，再进行付款操作。</p>
      <p class="info">如果需要开具发票，在付款完成后点击左侧菜单“支付订单”找到该笔付款订单进行申请开发票。</p>
    </div>
  </div>
</template>

<script>
  import MemberBreadcrumb from '../common/MemberBreadcrumb'
  import objectUtil from 'tdw-object-util'

  export default {
    name: 'CreatePayOrder',
    components: {MemberBreadcrumb},
    data () {
      return {
        title: '发起付款',
        model: {
          payChannel: '1',
          payMoney: '',
          payType: '1',
          projectId: this.$route.params.id
        },
        info: {},
        loading: false,
        rules: {
          payMoney: [
            {required: true, message: '请输入付款金额', 'trigger': 'blur'},
            {pattern: /^[1-9]\d*$/, message: '付款金额只能是整数', trigger: 'blur'}
          ]
        }
      }
    },
    beforeCreate () {
      const self = this
      self.$http.get({
        url: '/project/detail.do',
        data: {id: self.$route.params.id},
        success (res) {
          self.info = res.data
        }
      })
    },
    methods: {
      submitForm (formName) {
        const self = this
        self.$refs[formName].validate((valid) => {
          if (!valid) {
            return false
          }
          const payMoney = self.model.payMoney * 100
          if (payMoney > self.info.price - self.info.moneyPaid) {
            self.$message.error('付款金额不能超过项目剩余款项')
            return false
          }
          const data = objectUtil.copy(self.model)
          data.payMoney = payMoney
          self.loading = true
          self.$http.post({
            url: '/payOrder/create.do',
            data,
            success () {
              self.$router.push({name: 'payOrderList'})
            },
            always () {
              self.loading = false
            }
          })
        })
      }
    }
  }
</script>

<style scoped>
  p {
    line-height: 30px;
  }
</style>